import { styled } from 'config/stitches.config'

export const StyledVideo = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 0,

  '&::before': {
    display: 'none',
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,1)',
    zIndex: 12,
    mixBlendMode: 'overlay',
  },

  '.plyr': {
    height: '100%',

    'video': { objectFit: 'cover' }
  }
})
