import React, {
  forwardRef,
  useEffect, useRef,
  // useState
} from 'react'
// import { motion } from 'framer-motion'
import { usePlyr } from 'plyr-react'
import 'plyr-react/plyr.css'

import { StyledVideo } from './styles'

const PlyrVideo = forwardRef((props, ref) => {
  const {
    source, options = null,
    // setState,
    ...rest
  } = props
  const raptorRef = usePlyr(ref, {
    source,
    options,
  })

  useEffect(() => {
    if (!raptorRef?.current || !raptorRef.current?.plyr || !raptorRef.current?.plyr?.media) return

    raptorRef.current.plyr.on('ready', () => {
      // setTimeout(() => {
      //   if (raptorRef.current && !!raptorRef.current?.plyr && raptorRef.current?.plyr?.media) {
      //     raptorRef.current.plyr.media.autoPlay = true
      //     raptorRef.current.plyr.media.play()
      //   }
      // }, 3000)
      // setState('visible')
    })
  }, [raptorRef])

  return <video ref={raptorRef} className="plyr-react plyr" {...rest} />
})

PlyrVideo.displayName = 'PlyrVideo'

// const videoVariants = {
//   hidden: {
//     display: 'none',
//     opacity: 0,
//   },
//   visible: {
//     display: 'block', opacity: 1,
//   }
// }
const Video = ({
  mediaProps,
  source, // https://github.com/sampotts/plyr#the-source-setter
  options, // https://github.com/sampotts/plyr#options
}) => {
  const ref = useRef()
  // const [state, setState] = useState('hidden')


  const videoOpts = {
    source,
    muted: true,
    autoPlay: false,
    options: {
      loadSprite: false,
      loop: { active: true },
      volume: 0,
      debug: false,
      controls: [],
      clickToPlay: false,
      muted: 'true',
      autoplay: 'true',
      ...options
    },
    ...mediaProps,
    // poster: 'https://d33z3asz5zf4vc.cloudfront.net/Progetto_senza_titolo_2024_02_14_T143928_159_7aff43c83c.png?test=2'
  }

  return (
    <StyledVideo
      // as={motion.div}
      // variants={videoVariants}
      // initial="hidden"
      // animate={state}
    >
      <PlyrVideo ref={ref} {...videoOpts}
      // setState={setState}
      />
    </StyledVideo>
  )
}

export default Video
